import { ReactComponent as ActionsIcon } from "../assets/ActionsIcon.svg";
import { ReactComponent as ApiDocIcon } from "../assets/ApiDocIcon.svg";
import { ReactComponent as ModulesIcon } from "../assets/ModulesIcon.svg";
import { ReactComponent as SourcesIcon } from "../assets/SourcesIcon.svg";
import { ReactComponent as StorageIcon } from "../assets/StorageIcon.svg";
import { ReactComponent as TargetIcon } from "../assets/TargetIcon.svg";
import { ReactComponent as WorkflowsIcon } from "../assets/WorkflowsIcon.svg";
import { ReactComponent as MasterRecords } from "../assets/MasterRecords.svg";
import { ReactComponent as Chart } from "../assets/Chart.svg";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";

export const ItemNavMenu = [
  {
    label: "Dashboards",
    link: "/dashboards",
    icon: <Chart />,
  },
  {
    label: "API Keys",
    link: "/api-keys",
    icon: <SourcesIcon />,
  },
  {
    label: "Databases",
    link: "/databases",
    icon: <StorageIcon />,
  },
  {
    label: "Targets",
    link: "/outgoing",
    icon: <TargetIcon />,
  },
  {
    label: "Groups",
    link: "/groups",
    icon: <GroupWorkOutlinedIcon fontSize={"large"} />,
  },
  {
    label: "Processing",
    link: "/actions",
    icon: <ActionsIcon />,
  },
  {
    label: "Workflows",
    link: "/workflows",
    create: "/CreateWorkflows",
    update: "/workflows:id",
    icon: <WorkflowsIcon />,
  },
  {
    label: "Modules",
    link: "/modules",
    icon: <ModulesIcon />,
  },
  {
    label: "Analytics",
    link: "/analytics",
    icon: <LeaderboardOutlinedIcon fontSize={"large"} />,
  },
  {
    label: "Master Records",
    link: "/master-records",
    icon: <MasterRecords />,
  },
  {
    label: "API Documentation",
    link: "/doc",
    icon: <ApiDocIcon />,
  },
];
