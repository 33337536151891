import axios from "axios";
import moment from "moment";
import { apiDataBaseUrl, headersToken } from "../config/config";

export const getStats = async () =>
  await axios
    .get(`${apiDataBaseUrl}/stats`, {
      ...headersToken(),
    })
    .then((res) => res.data);

export const signIn = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/auth/login`,
    data
  );
  return response;
};

export const getConfig = async () => {
  const { data: response } = await axios.get(
    `${apiDataBaseUrl}/settings/config`,
    {
      ...headersToken(),
    }
  );
  return response;
};

// S T O R A G E S
export const getStorages = async () =>
  await axios
    .get(`${apiDataBaseUrl}/storages`, {
      ...headersToken(),
    })
    .then((res) => res.data);

export const getStoragesCollections = async (
  collectionName,
  page,
  rowsPerPage,
  searchTerm
) =>
  await axios
    .get(`${apiDataBaseUrl}/storages/collections/${collectionName}`, {
      params: {
        page: page + 1,
        limit: rowsPerPage,
        searchTerm: searchTerm,
      },
      ...headersToken(),
    })
    .then((res) => res.data);

export const postStaticData = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/matching-databases/search`,
    data,
    { ...headersToken() }
  );
  return response;
};

export const getStaticData = async () =>
  await axios
    .get(`${apiDataBaseUrl}/matching-databases`, {
      ...headersToken(),
    })
    .then((res) => res.data);

export const getLink = async (id) =>
  await axios
    .get(`${apiDataBaseUrl}/matching-databases/search/export/${id}`, {
      ...headersToken(),
    })
    .then((res) => res.data);

export const getStorage = async (id) =>
  await axios
    .get(`${apiDataBaseUrl}/storages/${id}`, {
      ...headersToken(),
    })
    .then((res) => res.data);

export const postStorages = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/storages`,
    data,
    { ...headersToken() }
  );
  return response.data;
};

export const editStorages = async (data) => {
  const { data: response } = await axios.put(
    `${apiDataBaseUrl}/storages/${data.id}`,
    data.data,
    { ...headersToken() }
  );
  return response.data;
};

export const deleteStorages = async (id) => {
  const { data: response } = await axios.delete(
    `${apiDataBaseUrl}/storages/${id}`,
    { ...headersToken() }
  );
  return response.data;
};

export const postConnectCollection = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/matching-databases`,
    data,
    { ...headersToken() }
  );
  return response.data;
};

export const getMasterDatabase = async (limit, cursor, sortOrder,fromFormatedDate,toFormatedDate) => {
    const params = {
      limit: limit,
      cursor: cursor,
      sortOrder: sortOrder,
      from: `'${fromFormatedDate}'`,
      to: `'${toFormatedDate}'`
    };
  
    const response = await axios.get(`${apiDataBaseUrl}/master-records`, {
      params: params,
      ...headersToken(),
    });
  
    return response.data;
  }
  

export const getMasterDatabaseByHash = async (hash) => {
    const response = await axios.get(`${apiDataBaseUrl}/master-records/${hash}`,{
        ...headersToken(),
    })
    return response.data
}

export const postMasterDatabaseSearch = async (data) => {
    const {data: response} = await axios.post(
        `${apiDataBaseUrl}/master-records/search`,
        data,
        {...headersToken()}
    );
    return response.result;
    }


// S O U R C E S
export const getSources = async () =>
  await axios.get(`${apiDataBaseUrl}/sources`, {
    ...headersToken(),
  });

export const getActiveSources = async () =>
  await axios
    .get(`${apiDataBaseUrl}/sources?active=true`, {
      ...headersToken(),
    })
    .then((res) => res.data);

export const getSource = async (id) =>
  await axios
    .get(`${apiDataBaseUrl}/sources/${id}`, {
      ...headersToken(),
    })
    .then((res) => res.data);

export const postSources = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/sources`,
    data,
    { ...headersToken() }
  );
  return response.data;
};

export const importFile = async (data) => {
  const { data: response } = await axios.post(
    `${process.env.REACT_APP_GATEWAY_API_URL}/import`,
    data.file,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.apiKey}`,
      },
    }
  );
  return response.data;
};

// New API
export const getChartByRange = async (data) => {
  const { data:response } = await axios.post(
    `${apiDataBaseUrl}/charts/by-range`,
    {
      from: `${moment(data.from).unix()}`,
      to: `${moment(data.to).unix()}`,
    },
    {
      ...headersToken(),
    });
    return response.result
    
}

// Deprecated
export const getStatsChart = async (data) => {
  const { data:response } = await axios.get(
    `${apiDataBaseUrl}/charts/full`, {
      ...headersToken(),
    });
    return response.result
    
}

// Not used
export const getSourcesStatsDaily = async (data) => {
  const date = new Date();
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/charts/incoming`,
    {
      from: `${moment(date).format("YYYY-MM-DD")}`,
      to: `${moment(date).format("YYYY-MM-DD")}`,
    },
    { ...headersToken() }
  );
  return response.result;
};

// Not used
export const getSourcesStatic30 = async (data) => {
  const date2 = new Date();
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/charts/incoming`,
    {
      from: `${moment(date).format("YYYY-MM-DD")}`,
      to: `${moment(date2).format("YYYY-MM-DD")}`,
    },
    { ...headersToken() }
  );
  return response.result;
};

// Not used
export const getSourcesStatic7 = async (data) => {
  const date2 = new Date();
  const date = new Date();
  date.setDate(date.getDate() - 7);
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/charts/incoming`,
    {
      from: `${moment(date).format("YYYY-MM-DD")}`,
      to: `${moment(date2).format("YYYY-MM-DD")}`,
    },
    { ...headersToken() }
  );
  return response.result;
};

// Not used
export const getSourcesStaticTotal = async (data) => {
  const date2 = new Date();
  const date = new Date();
  date.setMonth(date.getMonth() - 6);
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/charts/incoming`,
    {
      from: `${moment(date).format("YYYY-MM-DD")}`,
      to: `${moment(date2).format("YYYY-MM-DD")}`,
    },
    { ...headersToken() }
  );
  return response.result;
};

// Not used
export const getSourcesStaticChart = async (data) => {
  const date2 = new Date();
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/charts/chart`,
    {
      from: `${moment(date).format("YYYY-MM-DD")}`,
      to: `${moment(date2).format("YYYY-MM-DD")}`,
    },
    { ...headersToken() }
  );
  // eslint-disable-next-line
  response.result.map((source) => {
    for (let i = source.data.length - 1; i < 30; i++) {
      source.data.unshift(0);
    }
  });
  return response.result;
};

// Not used
export const getSourcesStaticChartWeek = async (data) => {
  const date2 = new Date();
  const date = new Date();
  date.setDate(date.getDate() - 7);
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/charts/chart`,
    {
      from: `${moment(date).format("YYYY-MM-DD")}`,
      to: `${moment(date2).format("YYYY-MM-DD")}`,
    },
    { ...headersToken() }
  );
  // eslint-disable-next-line
  response.result.map((source) => {
    for (let i = source.data.length - 1; i < 7; i++) {
      source.data.unshift(0);
    }
  });
  return response.result;
};

// Not used
export const getSourceDailyChart = async () => {
  const { data: response } = await axios.get(
    `${apiDataBaseUrl}/charts/daily-chart?type=source`,
    { ...headersToken() }
  );
  return response.result;
};

// Not used
export const getWorkflowDailyChart = async (type) => {
  const { data: response } = await axios.get(
    `${apiDataBaseUrl}/charts/daily-chart?type=workflow`,
    { ...headersToken() }
  );
  return response.result;
};

export const editSources = async (data) => {
  const { data: response } = await axios.put(
    `${apiDataBaseUrl}/sources/${data.id}`,
    data.data,
    { ...headersToken() }
  );
  return response.data;
};

export const generateApiKey = async () => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/utils/api-token`,
    {},
    { ...headersToken() }
  );
  return response;
};

export const deleteSources = async (id) => {
  const { data: response } = await axios.delete(
    `${apiDataBaseUrl}/sources/${id}`,
    { ...headersToken() }
  );
  return response.data;
};

export const getImportStatistic = async (id) =>
  await axios.get(`${apiDataBaseUrl}/sources/${id}/imports`, {
    ...headersToken(),
  });

export const deleteImportFile = async (data) => {
  const { data: response } = await axios.delete(
    `${apiDataBaseUrl}/sources/${data.sourceId}/imports/${data.fileId}`,
    { ...headersToken() }
  );
  return response.data;
};

export const postNewSources = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/sources/inside-workflow`,
    data,
    { ...headersToken() }
  );

  return response
};

// T A R G E T S
export const getTargets = async () => {
  const { data: response } = await axios.get(`${apiDataBaseUrl}/targets`, {
    ...headersToken(),
  });
  return response;
};

export const getTarget = async (id) => {
  const { data: response } = await axios.get(
    `${apiDataBaseUrl}/targets/${id}`,
    { ...headersToken() }
  );
  return response;
};

export const postTargets = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/targets`,
    data,
    { ...headersToken() }
  );
  return response;
};

export const editTargets = async (data) => {
  const { data: response } = await axios.put(
    `${apiDataBaseUrl}/targets/${data.id}`,
    data.data,
    { ...headersToken() }
  );
  return response.data;
};

export const deleteTargets = async (id) => {
  const { data: response } = await axios.delete(
    `${apiDataBaseUrl}/targets/${id}`,
    { ...headersToken() }
  );
  return response.data;
};

// GROUPS
export const getGroups = async () => {
  const { data: response } = await axios.get(`${apiDataBaseUrl}/groups`, {
    ...headersToken(),
  });
  return response;
};

export const postGroups = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/groups`,
    data,
    { ...headersToken() }
  );
  return response;
};

export const getGroup = async (id) => {
  const { data: response } = await axios.get(
    `${apiDataBaseUrl}/groups/${id}`,
    { ...headersToken() }
  );
  return response;
};

export const editGroups = async (data) => {
  const { data: response } = await axios.put(
    `${apiDataBaseUrl}/groups/${data.id}`,
    data.data,
    { ...headersToken() }
  );
  return response;
};
export const deleteGroups = async (id) => {
  const { data: response } = await axios.delete(
    `${apiDataBaseUrl}/groups/${id}`,
    { ...headersToken() }
  );
  return response;
};

// A C T I O N S
export const getActions = async () => {
  const { data: response } = await axios.get(`${apiDataBaseUrl}/actions`, {
    ...headersToken(),
  });
  return response;
};

export const getAction = async (id) => {
  const { data: response } = await axios.get(
    `${apiDataBaseUrl}/actions/${id}`,
    { ...headersToken() }
  );
  return response;
};

export const postActions = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/actions`,
    data,
    { ...headersToken() }
  );
  return response;
};

export const editActions = async (data) => {
  const { data: response } = await axios.put(
    `${apiDataBaseUrl}/actions/${data.id}`,
    data.data,
    { ...headersToken() }
  );
  return response;
};
export const deleteActions = async (id) => {
  const { data: response } = await axios.delete(
    `${apiDataBaseUrl}/actions/${id}`,
    { ...headersToken() }
  );
  return response;
};

// W O R K F L O W S
export const getWorkflows = async () => {
  const { data: response } = await axios.get(`${apiDataBaseUrl}/workflows`, {
    ...headersToken(),
  });
  return response;
};

export const getWorkflow = async (id) => {
  if (id) {
    const { data: response } = await axios.get(
      `${apiDataBaseUrl}/workflows/${id}`,
      { ...headersToken() }
    );
    return response;
  }
};

export const postWorkflows = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/workflows`,
    data,
    { ...headersToken() }
  );
  return response;
};

export const editWorkflows = async (data) => {
  const { data: response } = await axios.put(
    `${apiDataBaseUrl}/workflows/${data.id}`,
    data.data,
    { ...headersToken() }
  );
  return response;
};

export const editWorkflowSteps = async (data) => {
  const { data: response } = await axios.put(
    `${apiDataBaseUrl}/workflows/${data.workflowId}/workflow-steps/${data.stepId}`,
    data.data,
    { ...headersToken() }
  );
  return response;
};

export const postWorkflowSteps = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/workflows/${data.id}/workflow-steps`,
    data.data,
    { ...headersToken() }
  );
  return response;
};

export const deleteWorkflows = async (id) => {
  const { data: response } = await axios.delete(
    `${apiDataBaseUrl}/workflows/${id}`,
    { ...headersToken() }
  );
  return response;
};

export const deleteWorkflowSteps = async (data) => {
  const { data: response } = await axios.delete(
    `${apiDataBaseUrl}/workflows/${data.WorkflowId}/workflow-steps/${data.stepId}`,
    { ...headersToken() }
  );
  return response;
};

export const getWorkflowWeekStatic = async (data) => {
  const date2 = new Date();
  const date = new Date();
  date.setDate(date.getDate() - 7);
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/workflows/stats`,
    {
      from: `${moment(date).format("YYYY-MM-DD")}`,
      to: `${moment(date2).format("YYYY-MM-DD")}`,
    },
    { ...headersToken() }
  );
  // eslint-disable-next-line
  response.result.map((workflow) => {
    for (let i = workflow.data.length - 1; i < 7; i++) {
      let k = 0;
      workflow.data.unshift({
        ...workflow.data[k],
        workflowSteps: workflow.data[k].workflowSteps.map((step) => {
          return {
            ...step,
            totalIncome: 0,
            totalOutcome: 0,
          };
        }),
      });
      k++;
    }
  });
  return response.result;
};

export const getWorkflowMonthStatic = async (data) => {
  const date2 = new Date();
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/workflows/stats`,
    {
      from: `${moment(date).format("YYYY-MM-DD")}`,
      to: `${moment(date2).format("YYYY-MM-DD")}`,
    },
    { ...headersToken() }
  );
  // eslint-disable-next-line
  response.result.map((workflow) => {
    for (let i = workflow.data.length - 1; i < 30; i++) {
      let k = 0;
      workflow.data.unshift({
        ...workflow.data[k],
        workflowSteps: workflow.data[k].workflowSteps.map((step) => {
          return {
            ...step,
            totalIncome: 0,
            totalOutcome: 0,
          };
        }),
      });
      k++;
    }
  });
  return response.result;
};

export const getLogs = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/logs/search`,
    data,
    {
      ...headersToken(),
    }
  );
  return response;
};

// STATIC MODULES
export const getStaticModules = async () => {
  const { data: response } = await axios.get(`${apiDataBaseUrl}/modules`, {
    ...headersToken(),
  });
  return response;
};

// CUSTOM MODULES

export const postCustomModules = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/custom-modules`,
    data,
    { ...headersToken() }
  );
  return response;
};

export const getCustomModules = async () =>
  await axios
    .get(`${apiDataBaseUrl}/custom-modules`, {
      ...headersToken(),
    })
    .then((res) => res.data);

export const getCustomModule = async (id) =>
  await axios
    .get(`${apiDataBaseUrl}/custom-modules/${id}`, {
      ...headersToken(),
    })
    .then((res) => res.data);

export const deleteCustomModule = async (id) => {
  const { data: response } = await axios.delete(
    `${apiDataBaseUrl}/custom-modules/${id}`,
    { ...headersToken() }
  );
  return response.data;
};

export const editCustomModule = async (data) => {
  const { data: response } = await axios.put(
    `${apiDataBaseUrl}/custom-modules/${data.id}`,
    data.data,
    { ...headersToken() }
  );
  return response.data;
};

// S T A T I S T I C S

export const getStatistics = async (data) => {
  const { data: response } = await axios.post(
    `${apiDataBaseUrl}/charts/daily-match-by-source`,
    data,
    { ...headersToken() }
  );
  return response.result;
};

//
export const postSourceData = async (data) => {
  const { data: response } = await axios.post(`${apiDataBaseUrl}/search`, 
  data, 
  { ...headersToken()});

  return response.result
};
