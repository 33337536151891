import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Typography,
  Snackbar,
  Tabs,
  Tab,
  TableCell,
  TableRow,
  Modal,
} from "@mui/material";
import moment from 'moment'

import {useWorkflowAPI} from "../../hooks/useWorkFlowAPI";
import AddNewButton from "../../components/AddNewButton";
import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ListTable from "../../components/ListTable/ListTabel";
import Status from "../../components/Status/Status";
import TabPanel from "../../components/Tab/TabPanel";
import WorkflowStatictis from "../../components/Statistics/WorkflowStatictis/WorkflowStatistic";
import WorkflowChartStatictis from "../../components/Statistics/WorkflowStatictis/WorkflowChartStatistic";
import WorkflowsLogs from "./WorkflowsLogs";
import {
  deleteWorkflows,
  editWorkflows,
  getWorkflowWeekStatic,
  getWorkflowMonthStatic,
} from "../../services/DataService";
import {
  calculateTotalFromArray,
  calculateTotalFromArrayChart,
  calculateTotalProperty,
  processSteps
} from "../../utils/functions";
import { Alert } from "../../utils/alert";

import AngleDown from "../../assets/AngleDown.svg";
import AngleUp from "../../assets/AngleUp.svg";

import style from "./styles.module.css";

const WorkflowsPage = () => {
  const {workflowsData: {data, isLoading, isError}} = useWorkflowAPI();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [openActions, setOpenActions] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [weekData, setWeekData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [AllData, setAllData] = useState([]);
  const [value, setValue] = useState(0);
  const [alert, setAlert] = useState({
    isShow: false,
    type: "success",
    message: "Strategy created successfully",
  });
  const firstRun = useRef(true);

  const history = useNavigate();

  useEffect(() => {
    if (weekData?.length && monthData?.length) {
      const copyData = [];

      weekData.forEach(week => {
        monthData.forEach(month => {
          if (week._id === month._id) {
            const { WeekSteps, TodaySteps, WeekStepsChart, MonthStepsChart, MonthSteps } = processSteps(week, month);

            const newData = {
              _id: month._id,
              name: week.name,
              stepCount: week.workflowStepsSize,
              color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
              AllTodayIn: calculateTotalProperty(TodaySteps, 'totalIncome'),
              AllWeekIn: calculateTotalFromArray(week.data, 'totalIncome'),
              AllTodayOut: calculateTotalProperty(TodaySteps, 'totalOutcome'),
              AllWeekOut: calculateTotalFromArray(week.data, 'totalOutcome'),
              AllMonthIn: calculateTotalFromArray(month.data, 'totalIncome'),
              AllMonthOut: calculateTotalFromArray(month.data, 'totalOutcome'),
              AllWeekInChart: calculateTotalFromArrayChart(week.data, 'totalIncome'),
              AllWeekOutChart: calculateTotalFromArrayChart(week.data, 'totalOutcome'),
              AllMonthInChart: calculateTotalFromArrayChart(month.data, 'totalIncome'),
              AllMonthOutChart: calculateTotalFromArrayChart(month.data, 'totalOutcome'),
              stepsToday: Object.values(TodaySteps),
              stepsWeek: Object.values(WeekSteps),
              stepsMonth: Object.values(MonthSteps),
              stepsWeekChart: Object.values(WeekStepsChart).map(e => ({ ...e, totalIncome: e.totalIncome, totalOutcome: e.totalOutcome })),
              stepsMonthChart: Object.values(MonthStepsChart).map(e => ({ ...e, totalIncome: e.totalIncome, totalOutcome: e.totalOutcome })),
            };

            copyData.push(newData);
          }
        });
      });

      setAllData(copyData);
    }
  }, [weekData, monthData]);

  const queryClient = useQueryClient();
  const StaticOnWeek = useMutation(getWorkflowWeekStatic, {
    onSuccess: (data) => {
      setWeekData(data);
    },
    onError: (e) => {
      console.log(e);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const StaticOnMonth = useMutation(getWorkflowMonthStatic, {
    onSuccess: (data) => {
      setMonthData(data);
    },
    onError: (e) => {
      console.log(e);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  useEffect(() => {
    if (firstRun.current) {
      StaticOnWeek.mutate();
      StaticOnMonth.mutate();
      firstRun.current = false;
    }
  }, [StaticOnWeek, StaticOnMonth]);

  const Delete = useMutation(deleteWorkflows, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Workflow deleted successfully");
    },
    onError: () => {
      handleOpenAlert("error", "Oops, something went wrong");
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const edit = useMutation(editWorkflows, {
    onSuccess: (data) => {
      handleOpenAlert("success", "Workflow edited successfully");
    },
    onError: (err) => {
      handleOpenAlert("error", err.response.data.errors[0].msg);
    },
    onSettled: () => {
      queryClient.invalidateQueries("create");
    },
  });
  const handleOpenDeleteModal = (workflow) => {
    setDeleteId({ id: workflow._id, name: workflow.name });
    setShowDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleOpenAlert = (type, message) => {
    setAlert({ isShow: true, type: type, message });
  };
  const handleChangeActive = (id, active) => {
    edit.mutate({ data: { active: !active }, id: id });
  };
  const redirectAddNew = () => {
    history("/CreateWorkflows", { state: null });
  };
  const handleDeleteWorkflow = (workflow) => {
    handleCloseDeleteModal();
    Delete.mutate(deleteId.id);
  };
  const redirectIdworkflow = (source) => {
    history(`/workflows/${source._id}`);
  };
  const handleCloseAlert = () => {
    setAlert(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const underField = (element) => {
    return element.workflowSteps.map((e) => {
      let order;
      if (e.dependsOn) {
        order = element.workflowSteps.find((el) => {
          if (e.dependsOn === el._id) {
            return el;
          } else {
            return null;
          }
        });
      }
      return (
        <TableRow>
          <TableCell variant="workflows" sx={{ maxWidth: "200px" }}>
            {e?.action?.name}
          </TableCell>
          <TableCell variant="workflows" align="left" sx={{ maxWidth: "70px" }}>
            Depends on: {order ? `step ${order.order}` : "none"}
          </TableCell>
          <TableCell variant="workflows" align="left">-</TableCell>
          <TableCell variant="workflows" align="left"></TableCell>
          <TableCell variant="workflows" align="left"></TableCell>
        </TableRow>
      );
    });
  };
  const headers = [
    {
      lable: "Workflow",
      align: "left",
    },
    {
      lable: "Steps",
      align: "left",
    },
    {
      lable: "Sources",
      align: "left",
    },
    {
      lable: "Status",
      align: "left",
    },
    {
      lable: "Created at",
      align: "left",
    },
    {
      lable: " ",
      align: "left",
    },
  ];
  const columns = [
    {
      accessor: (element) => element["name"],
      Cell: (event, target) => {},
      align: "left",
    },
    {
      accessor: (element) => (
        <Typography>
          {element["workflowSteps"].length}
          <img
            style={{ marginLeft: "5px" }}
            src={openActions === element._id ? AngleUp : AngleDown}
            alt=""
          />
        </Typography>
      ),

      Cell: (event, workflow) => {
        event.stopPropagation();
        if (openActions !== workflow._id) {
          setOpenActions(workflow._id);
        } else {
          setOpenActions(null);
        }
      },
      align: "left",
    },
    {
      accessor: (element) =>
        element.sources.map((e) => (
          <Typography key={e._id}>{e.name}</Typography>
        )),
      Cell: (event, workflow) => {},
      align: "left",
    },
    {
      accessor: (element) => <Status status={element.active} />,
      Cell: (event, workflow) => {
        event.stopPropagation();
        handleChangeActive(workflow._id, workflow.active);
      },
      align: "left",
    },
    {
      accessor: (element) => moment(element.createdAt).format('YYYY-MM-DD HH:MM'),
      Cell: (event, action) => {},
      align: "left",
    },
  ];

  if (isLoading) {
    return (
      <Box className="wrapper-loading">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return <h1>Something wrong</h1>;
  }

  return (
    <>
      <Box className={style.tabs_box}>
        <Tabs
          value={value}
          onChange={handleChange}
        >
          <Tab label="Workflows" />
          <Tab label="Table Statistics" />
          <Tab label="Chart Statistics" />
          <Tab label="Logs" />
        </Tabs>
        <AddNewButton redirectAddNew={redirectAddNew} text={"Workflow"} />
      </Box>
      <TabPanel value={value} index={0}>
        <Box className="wrapper-source-list">
          <ListTable
            headers={headers}
            data={data}
            handleDelete={handleOpenDeleteModal}
            redirectId={redirectIdworkflow}
            columns={columns}
            openAction={openActions}
            underField={underField}
          />
        </Box>
        <Snackbar
          open={alert.isShow}
          autoHideDuration={3500}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={handleCloseAlert}
        >
          <Alert
            onClose={handleCloseAlert}
            severity={alert.type}
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WorkflowStatictis AllData={AllData} />
      </TabPanel>
      <TabPanel value={value} index={2} sx={{ padding: 0 }}>
        <WorkflowChartStatictis AllData={AllData} />
      </TabPanel>
      <TabPanel value={value} index={3} sx={{ padding: 0 }}>
        <WorkflowsLogs workflows={AllData} />
      </TabPanel>
      <Modal
        open={showDeleteModal}
        onClose={handleCloseDeleteModal}
        sx={{ backdropFilter: "blur(14px)" }}
      >
        <DeleteModal
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDelete={handleDeleteWorkflow}
          text={"Workflow"}
          name={deleteId?.name}
        />
      </Modal>
    </>
  );
};

export default WorkflowsPage;
